import React from 'react'
import Layout from '../components/layout'
import BackgroundVideo from '../components/backgroundVideo'
import styles from './styles.module.css'
import SEO from '../components/seo'

export default () => {
    return (
        <Layout>
            <SEO 
                title='home' 
            />
            <section className={styles.section}>
                <p className={styles.welcomeNote}>
                    A solo indie making <a href="https://outride.sappzo.com/"> Outride </a>
                </p> 
            </section>
            <BackgroundVideo/>
        </Layout>
    )
}
